var width = Math.max($(window).width(), window.innerWidth);
var height = window.innerHeight;

equalheight = function(container){
    var currentTallest = 0,
        currentRowStart = 0,
        rowDivs = new Array(),
        $el,
        topPosition = 0;
    $(container).each(function() {

        $el = $(this);
        $($el).height('auto')
        topPostion = $el.position().top;

        if (currentRowStart != topPostion) {
            for (currentDiv = 0 ; currentDiv < rowDivs.length ; currentDiv++) {
                rowDivs[currentDiv].height(currentTallest);
            }
            rowDivs.length = 0; // empty the array
            currentRowStart = topPostion;
            currentTallest = $el.height();
            rowDivs.push($el);
        } else {
            rowDivs.push($el);
            currentTallest = (currentTallest < $el.height()) ? ($el.height()) : (currentTallest);
        }
        for (currentDiv = 0 ; currentDiv < rowDivs.length ; currentDiv++) {
            rowDivs[currentDiv].height(currentTallest);
        }
    });
}

//applied globally on all textareas with the "autoExpand" class
$(document)
    .one('focus.autoExpand', 'textarea.autoExpand', function(){
        var savedValue = this.value;
        this.value = '';
        this.baseScrollHeight = this.scrollHeight;
        this.value = savedValue;
    })
    .on('input.autoExpand', 'textarea.autoExpand', function(){
        var minRows = this.getAttribute('data-min-rows')|0, rows;
        this.rows = minRows;
        rows = Math.ceil((this.scrollHeight - this.baseScrollHeight) / 16);
        this.rows = minRows + rows;
    });

//get scrollbar width
function scrollbarWidth() {
    var block = $('<div>').css({'height':'50px','width':'50px'}),
        indicator = $('<div>').css({'height':'200px'});

    $('body').append(block.append(indicator));
    var w1 = $('div', block).innerWidth();
    block.css('overflow-y', 'scroll');
    var w2 = $('div', block).innerWidth();
    $(block).remove();
    return (w1 - w2);
}

function popupOpen(e){
    e.preventDefault();
    var id = $(this).attr('href');
    $(id).fadeIn(400);
    $('body').addClass('is-popup-open');
    if($(id).find('.product-subs__offer').length > 0){
        equalheight('.product-subs__offer-info');
        equalheight('.product-subs__offer-term');
    }
    if($(id).find('.js-texblock-scroll').length > 0){
        $('.js-texblock-scroll').jScrollPane({
            autoReinitialise: true
        });
    }

    if($(id).find('.popup-pickpoint__scroller').length) {
        $('.popup-pickpoint__scroller').jScrollPane({mouseWheelSpeed: 20, autoReinitialise: true});
    }
}

function popupClose(e){
    e.preventDefault();
    var $this = $(this);
    $this.closest(".popup").fadeOut(400, function(){$('body').removeClass('is-popup-open');});
}

//check window size
function checkWindowSize() {
    $('.category-menu__list .has-dd').off();
    $('.dd-menu__li').off();
    $('.category-menu__list .has-dd > a').off();
    $('.dd-menu__li.has-child > a, .dd-menu__li.has-child span').off();
    $('.js-hint-toggle').off();
    $('.js-tooltip-toggle').off();
    $('.js-filter-subcat-toggle, .cat-filter__subcat-box').off();
    $('.js-popup-cart').off();
    $('.js-usermenu-toggle').off();
    $('.js-header-searchform').off('focus');

    if(width > 1150){
        //usermenu toggle
        $('.js-usermenu-toggle').on({
            mouseenter: function () {
                $(this).addClass('is-active');
                $('body').addClass('is-usermenu-open');
                $(this).children('.usermenu-dd').stop().fadeIn();
            },
            mouseleave: function () {
                $(this).removeClass('is-active');
                $('body').removeClass('is-usermenu-open');
                $(this).children('.usermenu-dd').stop().fadeOut();
            }
        });

        //popup cart
        $('.js-popup-cart').on({
            mouseenter: function () {
                $('.cart-popup').stop().fadeIn(350);
            },
            mouseleave: function () {
                $('.cart-popup').stop().fadeOut(200);
            }
        });

        //category-menu dd
        $('.category-menu__list .has-dd').on({
            mouseenter: function () {
                var childMenu = $('.dd-menu__childbox').eq(0);
                $(this).find('.dd-menu').stop(true).fadeIn(150);
                childMenu.show();
                $('.dd-menu').height(childMenu.outerHeight());
                $(this).addClass('is-open');
            },
            mouseleave: function () {
                $(this).find('.dd-menu').stop(true).fadeOut(150);
                $(this).removeClass('is-open');
            }
        });

        //dd-menu subcat
        $('.dd-menu__li.has-child').on({
            mouseenter: function () {
                var childMenu = $(this).find('.dd-menu__childbox');
                $(this).siblings().find('.dd-menu__childbox').hide();
                childMenu.stop(true).show();
                $(this).addClass('is-open');
                $('.dd-menu').height(childMenu.outerHeight());
                if(childMenu.hasClass('has-1col')){
                    //$('.dd-menu').width(460);
                }
            },
            mouseleave: function () {
                $(this).find('.dd-menu__childbox').stop(true).hide();
                $(this).removeClass('is-open');
                $('.dd-menu').height('auto');
                $('.dd-menu').width(960);
            }
        });

        //hint
        $('.js-hint-toggle').on({
            mouseenter: function () {
                $(this).parent().addClass('is-open').find('.hint-box__text').fadeIn(150);
            },
            mouseleave: function () {
                $(this).parent().removeClass('is-open').find('.hint-box__text').fadeOut(150);
            }
        });

        //filter-subcat-toggle
        $('.cat-filter__subcat-box').on({
            mouseenter: function () {
                $(this).toggleClass('is-open');
            },
            mouseleave: function () {
                $(this).toggleClass('is-open');
            }
        });
    }else{
        //usermenu toggle
        $('.js-usermenu-toggle').on('click', function(e) {
            e.stopPropagation();
            $(this).toggleClass('is-active');
            $('body').toggleClass('is-usermenu-open');
            $(this).children('.usermenu-dd').fadeToggle();
        });
        $(document).on('click', function(e) {
            if($('.js-usermenu-toggle').hasClass('is-active')) {
                $('.js-usermenu-toggle').trigger('click');
            }
        });

        $('.category-menu__list .has-dd > a').on('click', function(e) {
            $('.dd-menu__li.is-open').removeClass('is-open').find('.dd-menu__childbox').hide();
            $(this).parent('li').toggleClass('is-open');
            $(this).next().fadeToggle(150);
            e.preventDefault();
            var childMenu = $('.dd-menu__childbox').eq(0);
            childMenu.stop(true).show().closest('li').addClass('is-open');
            $('.dd-menu').height(childMenu.outerHeight());
        });

        $('.dd-menu__li.has-child > a, .dd-menu__li.has-child span').on('click', function(e) {
            $('.dd-menu').height('auto');
            $('.dd-menu__li.has-child > a, .dd-menu__li.has-child span').not(this).closest('li').removeClass('is-open').find('.dd-menu__childbox').hide();
            $(this).closest('li').addClass('is-open').find('.dd-menu__childbox').show();
            $('.dd-menu').height($(this).parent('li').find('.dd-menu__childbox').outerHeight());
            e.preventDefault();
        });

        $('.js-hint-toggle').on('click', function(e) {
            e.stopPropagation();
            $('.js-hint-toggle').not(this).parent().removeClass('is-open').find('.hint-box__text').hide();
            $(this).parent().toggleClass('is-open').find('.hint-box__text').toggle();
        });

        //filter-subcat-toggle
        $('body').on('click', '.js-filter-subcat-toggle', function(e) {
            $(this).parent().toggleClass('is-open');
        });
    }

    $('.js-order-step-toggle').off();
    if(width <= 750){
        //order-step-toggle in catalog
        $('.js-order-step-toggle').on('click', function(e) {
            $(this).toggleClass('is-open').next().slideToggle();
        });
    }

    // header fixed
    const willHeaderFixed = !$('body').find('.product-fixed').length && $('.header').length;

    if(willHeaderFixed) {
        window.onscroll = () => headerFixed();
    }

    function headerFixed() {
        if ($(window).scrollTop() > $('.header').height() * 2) {
            $('body').addClass('is-header-fixed');
        } else {
            $('body').removeClass('is-header-fixed');
        }
    }
    // END header fixed

    //rentrules
    $('.js-rentrule-toggle').off();
    var rentruleSlider = false;
    if(width <= 750){
        $('.js-rentrule-toggle').on('click', function(e) {
            $(this).toggleClass('is-open').next().slideToggle();
        });
    }

    // searchform
    $('.js-searchform-input').on('focus', () => {
        if (width <= 750) {
            searchHelperShow();
            setTimeout(() => $('.searchform-helper__input').trigger('focus'), 100);
        } else {
            $('.header').addClass('is-search-active');
        }
    });
    $('.js-searchform-input').on('blur', function() {
        if (!$(this).val()) {
            $('.header').removeClass('is-search-active');
        }
    });

    /*$('.js-searchform-input').on('input', function() {
        $(this).val().trim() ? searchHelperShow() : searchHelperHide();
    });*/

    $('.searchform').on('reset', searchHelperHide);

    $('.js-search-helper-close').on('click', searchHelperHide);

    $('.js-search-open').on('click', function(e) {
        e.stopPropagation();
        $('.header').addClass('is-search-active');
        setTimeout(() => $('.searchform__input').trigger('focus'), 100);
    });
    $(document).on('click', function(e) {
        if (!$(e.target).closest('.searchform').length) {
            searchHelperHide();
        }
    });

    function searchHelperShow() {
        $('body').addClass('is-search-helper-open');
    }
    function searchHelperHide(e) {
        $('.searchform-helper__input').val('');
        $('.js-searchform-input').val('').trigger('blur');

        if (width > 750 || e?.type !== 'reset') {
            $('body').removeClass('is-search-helper-open');
        }
    }
    // END searchform

    $('.js-topmenu-subcat-toggle').off();
    if(width < 768) {
        $('.js-topmenu-subcat-toggle').on('click', function(e) {
            e.preventDefault();
            e.stopPropagation();
            $('.js-topmenu-subcat-toggle').not(this).removeClass('is-open').next().slideUp();
            $(this).toggleClass('is-open').next().slideToggle();
        });
    }

}

$(window).on('load', function () {
    if($('body').height() > height)
        $('head').append('<style>body.is-popup-open{margin-right:'+ scrollbarWidth() +'px;}</style>');

    equalheight('.product-subs__offer-info');
    equalheight('.product-subs__offer-term');
});

$(document).ready(function() {
    width = Math.max($(window).width(), window.innerWidth);
    height = $(window).height();

    checkWindowSize();

    // tippy
    tippy('.tooltip', {
        content(reference) {
            const template = reference.nextElementSibling;
            return template.innerHTML;
        },
        allowHTML: true,
        interactive: true,
        maxWidth: 340,
        offset: [0, 10],
        appendTo: document.body
    });

    // inputmask
    $('.js-input-tel').inputmask({
        mask: '+7 (999) 999-99-99',
        showMaskOnHover: false,
        clearIncomplete: true
    });
    $('.js-input-date').inputmask({
        mask: '99.99.9999',
        showMaskOnHover: false,
        clearIncomplete: true
    });
    $('.js-input-passport').inputmask({
        mask: '9999  -  999999',
        showMaskOnHover: false,
        clearIncomplete: true
    });
    $('.js-input-card-number').inputmask({
        mask: '9999  -  9999  -  9999  -  9999',
        showMaskOnHover: false,
        clearIncomplete: true
    });
    $('.js-input-card-date').inputmask({
        mask: '99/99',
        showMaskOnHover: false,
        clearIncomplete: true
    });
    window.addMasks(document, '.js-datebirth', 'date'); //

    //price-input
    $('.js-price-input').on('keyup input', function() {
        $(this).val($(this).val().replace(/\s/g, "").replace(/(\d)(?=(\d{3})+(\D|$))/g, '$1 '));
    });

    //scroll-link
    $(".js-scroll-link").on('click', function(e) {
        e.preventDefault();
        var $this = $(this);
        $('html, body').stop().animate({
            scrollTop: $($this.attr('href')).offset().top - 115
        }, 550, 'easeOutQuad');
    });

    $('[data-fancybox]').fancybox({
        buttons: [
            "close"
        ],
    });

    //password switch
    $('body').on('click', '.js-pass-toggle', function (e) {
        $(this).toggleClass('is-visible');
        if($(this).hasClass('is-visible')){
            $(this).closest('.inputbox__field').find('.inputbox__input').attr('type', 'text');
        }else{
            $(this).closest('.inputbox__field').find('.inputbox__input').attr('type', 'password');
        }
    });

    //passport fields
    $('body').on('click', '.js-passport-toggle', function (e) {
        $(this).toggleClass('is-visible');
        if($(this).hasClass('is-visible')){
            $(this).closest('.inputbox-passport').find('.inputbox__input').attr('type', 'text');
        }else{
            $(this).closest('.inputbox-passport').find('.inputbox__input').attr('type', 'password');
        }
    });
    $(".js-passport-field").keyup(function () {
        if (this.value.length == this.maxLength) {
            $(this).closest('.inputbox-passport').find('.js-passport-field').eq(1).focus();
        }
    });

    //select decorated
    $('.selectstyle').each(function(i){
        var $this = $(this), numberOfOptions = $(this).children('option').length;

        $this.addClass('select-hidden');
        $this.wrap('<div class="select"></div>');
        $this.after('<div class="select-styled"><span></span></div>');

        var $styledSelect = $this.next('div.select-styled');
        $this.next('div.select-styled').find('span').text($this.children('option').eq(0).text());

        var $list = $('<ul />', {
            'class': 'select-options'
        }).insertAfter($styledSelect).wrap('<div class="select-options-wrap"></div>');

        for (var i = 0; i < numberOfOptions; i++) {
            $('<li />', {
                text: $this.children('option').eq(i).text(),
                rel: $this.children('option').eq(i).val()
            }).appendTo($list);
        }

        var $listItems = $list.children('li');

        $styledSelect.click(function(e) {
            e.stopPropagation();
            $('div.select-styled.active').not(this).each(function(){
                $(this).removeClass('active');
            });
            $(this).toggleClass('active');
            if($(this).hasClass('active'))
                $(this).next('.select-options-wrap').find('.select-options').jScrollPane();
            $('.js-selectbox-toggle').closest('.selectbox').removeClass('is-open');
        });

        $listItems.click(function(e) {
            e.stopPropagation();
            $(this).siblings().removeClass('is-selected');
            $(this).addClass('is-selected');
            $styledSelect.find('span').text($(this).text());
            $styledSelect.removeClass('active');
            $this.val($(this).attr('rel'));
            //console.log($this.val());
        });

        $list.find('li').eq($this.prop('selectedIndex')).trigger('click');

        $(document).click(function() {
            $styledSelect.removeClass('active');
        });
    });//END select decorated

    $(document).click(function() {
        $('.js-lang-toggle').removeClass('is-open');
    });
    $('.js-lang-toggle').on('click', function(e) {
        e.stopPropagation();
    })

    //
    $(document).click(function() {
        $('.js-hint-toggle').parent().removeClass('is-open').find('.__text').hide();
    });

    //input reset
    $('body').on('click', '.js-input-reset', function(e) {
        $(this).prev().attr('value', '');
        console.log($(this).prev().val());
    });

    //popup
    $('.js-popup-link').on('click', popupOpen);
    $('.js-popup-link-inpopup').on('click', function(e){
        e.preventDefault();
        var id = $(this).attr('href');
        $(".popup").fadeOut(400);
        $(id).fadeIn(400);
    });
    $("body").on('click', '.js-popup-close', popupClose);
    $("body").on('keyup', function(e){
        if (e.keyCode == 27 && $('body').hasClass('is-popup-open')) {
            $(".popup").fadeOut(400);

            setTimeout(function(){
                $('body').removeClass('is-popup-open');
            }, 400);
        }
    });

    $('#tariff_month_radio').click(function(){
        $('#month_form').val('sub_fix');
        $('#name_period').text('месяц');
        if ($(this).data('is-today')) {
            return;
        }
        const form = $(this).closest('form');
        const startInput = form.find('.date_start');
        const button = form.find('button');
        startInput.val($(this).data('earliest-date'));
        button.find('span').text(`Доступно с ${$(this).data('earliest-date-text')}`);
    });

    $('#tariff_week_radio').click(function(){
        $('#month_form').val('sub_fix_week');
        $('#name_period').text('в неделю');
        if ($(this).data('is-today')) {
            return;
        }
        const form = $(this).closest('form');
        const startInput = form.find('.date_start');
        const button = form.find('button');
        startInput.val($(this).data('earliest-date'));
        button.find('span').text(`Доступно с ${$(this).data('earliest-date-text')}`);
    });

    //catalog popup
    $('body').on('click', '.js-catalog-popup-open', function(e) {
        e.preventDefault();
        $('body').toggleClass('is-catalog-popup-open');
    });
    $('body').on('click', '.js-catalog-popup-close', function(e) {
        var name = $('.catalog-popup__title').data('name');
        $('.catalog-popup__title').text(name);
        $('body').removeClass('is-catalog-popup-open');
        $('.catalog-popup').removeClass('is-submenu-open');
        $('.catalog-popup__submenu').removeClass('is-open');
        $('.catalog-popup__submenu.is-ajax').remove();
    });
    $('body').on('click', '.js-catalog-popup-back', function(e) {
        if($('.catalog-popup__submenu.is-open').length > 1){
            var name = $('.catalog-popup__submenu.is-open').eq(-2).data('title');
            $('.catalog-popup__submenu.is-open').eq(-1).removeClass('is-open');
        }else{
            var name = $('.catalog-popup__title').data('title');
            $('.catalog-popup').removeClass('is-submenu-open');
            $('.catalog-popup__submenu').removeClass('is-open');
        }
        $('.catalog-popup__title').text(name);
        setTimeout(function(){
            $('.catalog-popup__submenu.is-ajax').eq(-1).remove();
        },400);
    });
    $('body').on('click', '.catalog-popup__menu-link.has-child', function(e) {
        var name = $(this).find('.catalog-popup__menu-name').text();
        $('.catalog-popup__title').text(name);
        var menu = $(this).data('menu');
        $('.catalog-popup').addClass('is-submenu-open');
        $('#'+menu).addClass('is-open');
    });
    $('body').on('click', '.catalog-popup__submenu-link.has-child', function(e) {
        var name = $(this).text();
        $('.catalog-popup__title').text(name);
        var menu = $(this).data('menu');
        $('#'+menu).addClass('is-open');
    });

    //header tooltips
    $('.header__rmenu > .js-tooltip-toggle').on({
        mouseenter: function () {
            $(this).stop().addClass('has-tooltip');
        },
        mouseleave: function () {
            $(this).stop().removeClass('has-tooltip');
        }
    });

    //topmenu toggle
    $('body').on('click', '.js-topmenu-toggle', function(e) {
        e.stopPropagation();
        e.preventDefault();
        if($('body').find('.js-tarif-cont-toggle.is-open').length > 0){
            $('.js-tarif-cont-toggle.is-open').trigger('click');
        }
        $('.js-topmenu-toggle').toggleClass('is-active');
        if(width > 750){
            $('.topmenu').fadeToggle();
            //$('body').toggleClass('is-topmenu-overlay');
        }else{
            $('body').toggleClass('is-topmenu-open');
            setTimeout(function() {
                var topmenuOffset = $('.header').offset().top +  $('.header').height() + 1;
                $('.topmenu').offset({ top: topmenuOffset});
            },100)
        }
    });
    $('.topmenu').click(function(e) {
        //e.stopPropagation();
    });
    $(document).click(function() {
        if($('.js-topmenu-toggle').hasClass('is-active')){
            $('.js-topmenu-toggle').first().trigger('click');
        }
    });

    //topmenu catalog
    $('body').on('click', '.js-topmenu-catalog-toggle', function(e) {
        e.preventDefault();
        e.stopPropagation();
        $(this).toggleClass('is-open').next().slideToggle();
    });

    //search toggle
    $('body').on('click', '.js-search-toggle', function(e) {
        e.preventDefault();
        if($('body').hasClass('is-usermenu-open')){
            $('.js-usermenu-toggle').trigger('click');
        }
        if($('body').hasClass('is-topsearch-open')){
            $('.js-topsearch-toggle').removeClass('is-active');
            $('body').removeClass('is-topsearch-open');
            $('.js-header-searchform').hide();
        }
        $(this).toggleClass('is-active');
        $('body').toggleClass('is-bottomsearch-open');
        $('.js-header-searchform').toggle(0);
        setTimeout(function(){
            if($('.js-header-searchform').is(':visible')){
                $('.searchform__input').click().focus();
            }
        }, 200);
    });

    // mobile top search toggle
    $('body').on('click', '.js-topsearch-toggle', function(e) {
        e.preventDefault();
        if($('body').hasClass('is-usermenu-open')){
            $('.js-usermenu-toggle').trigger('click');
        }
        if($('body').hasClass('is-bottomsearch-open')){
            $('.js-search-toggle').removeClass('is-active');
            $('body').removeClass('is-bottomsearch-open');
            $('.js-header-searchform').hide();
        }
        $(this).toggleClass('is-active');
        $('body').toggleClass('is-topsearch-open');
        $('.js-header-searchform').toggle();
        setTimeout(function(){
            if($('.js-header-searchform').is(':visible')){
                $('.searchform__input').click().focus();
            }
        }, 260);
    });

    //searchform mob helper catalog
    $('body').on('click', '.js-searchform-submenu-toggle', function(e) {
        e.preventDefault();
        e.stopPropagation();
        $('.js-searchform-submenu-toggle').not(this).removeClass('is-open').next().slideUp();
        $(this).toggleClass('is-open').next().slideToggle();
    });
    $('body').on('click', '.js-searchform-helper-catlist-toggle', function(e) {
        e.preventDefault();
        e.stopPropagation();
        $(this).toggleClass('is-open').next().slideToggle();
    });



    //mainpage cat bottom swiper
    if($('.page').find('.js-catbottom-swiper').length > 0){
        var catbottomSwiper = undefined;
        function initCatbottomSwiper() {
            if ( width <= 750 && (catbottomSwiper == undefined)) {
                catbottomSwiper = new Swiper('.js-catbottom-swiper', {
                    slidesPerView: 'auto',
                    slidesPerGroup: 1,
                    slidesOffsetBefore: 12,
                    slidesOffsetAfter: 12,
                    spaceBetween: 10,
                    loop: false,
                });
                catbottomSwiper.on('reachEnd', function () {
                    $('.js-catbottom-swiper').addClass('is-end');
                });
                catbottomSwiper.on('progress', function (progress) {
                    if(progress >= 1){
                        $('.js-catbottom-swiper').addClass('is-end');
                    }else{
                        $('.js-catbottom-swiper').removeClass('is-end');
                    }
                });
            }else if (width > 750 && (catbottomSwiper != undefined)) {
                catbottomSwiper.destroy();
                catbottomSwiper = undefined;
                $('.js-catbottom-swiper').find('.swiper-wrapper').removeAttr('style');
                $('.js-catbottom-swiper').find('.swiper-slide').removeAttr('style');
                $('.js-catbottom-swiper').find('.swiper-slide-duplicate').remove();
            }
        }
        initCatbottomSwiper();
        $(window).on('resize orientationchange', function() {
            width = Math.max($(window).width(), window.innerWidth);
            initCatbottomSwiper();
        });
    }

    //mainpage cat right swiper
    if($('.page').find('.js-catr-swiper').length > 0){
        var catrSwiper = undefined;
        function initCatrSwiper() {
            if ( width <= 750 && (catrSwiper == undefined)) {
                catrSwiper = new Swiper('.js-catr-swiper', {
                    slidesPerView: 'auto',
                    slidesPerGroup: 1,
                    slidesOffsetBefore: 12,
                    slidesOffsetAfter: 12,
                    spaceBetween: 7,
                    loop: false
                });
            }else if (width > 750 && (catrSwiper != undefined)) {
                catrSwiper.destroy();
                catrSwiper = undefined;
                $('.js-catr-swiper').find('.swiper-wrapper').removeAttr('style');
                $('.js-catr-swiper').find('.swiper-slide').removeAttr('style');
                $('.js-catr-swiper').find('.swiper-slide-duplicate').remove();
            }
        }
        initCatrSwiper();
        $(window).on('resize orientationchange', function() {
            width = Math.max($(window).width(), window.innerWidth);
            initCatrSwiper();
        });
    }

    //mainpage news swiper
    if($('.page').find('.js-news-swiper').length > 0){
        var newSwiper = undefined;
        function initNewSwiper() {
            if ( width <= 750 && (newSwiper == undefined)) {
                newSwiper = new Swiper('.js-news-swiper', {
                    slidesPerView: 'auto',
                    slidesOffsetBefore: 12,
                    spaceBetween: 10,
                    slidesOffsetAfter: 12,
                });
            }else if (width > 750 && (newSwiper != undefined)) {
                newSwiper.destroy();
                newSwiper = undefined;
                $('.js-news-swiper').find('.swiper-wrapper').removeAttr('style');
                $('.js-news-swiper').find('.swiper-slide').removeAttr('style');
                $('.js-news-swiper').find('.swiper-slide-duplicate').remove();
            }
        }
        initNewSwiper();
        $(window).on('resize orientationchange', function() {
            width = Math.max($(window).width(), window.innerWidth);
            initNewSwiper();
        });
    }

    //.js-massmedia-swiper
    if($('.page').find('.js-massmedia-swiper').length > 0){
        var massmediaSwiper = undefined;
        function initMassmediaSwiper() {
            if ( width <= 750 && (massmediaSwiper == undefined)) {
                massmediaSwiper = new Swiper('.js-massmedia-swiper', {
                    slidesPerView: 'auto',
                    slidesOffsetBefore: 12,
                    slidesOffsetAfter: 12,
                });
            }else if (width > 750 && (massmediaSwiper != undefined)) {
                massmediaSwiper.destroy();
                massmediaSwiper = undefined;
                $('.js-massmedia-swiper').find('.swiper-wrapper').removeAttr('style');
                $('.js-massmedia-swiper').find('.swiper-slide').removeAttr('style');
                $('.js-massmedia-swiper').find('.swiper-slide-duplicate').remove();
            }
        }
        initMassmediaSwiper();
        $(window).on('resize orientationchange', function() {
            width = Math.max($(window).width(), window.innerWidth);
            initMassmediaSwiper();
        });
    }

    // reviews swiper
    if($('.js-reviews-slider').length){
        let reviewSwiper = undefined;
        function initReviewSwiper() {
            if (width < 767 && (reviewSwiper == undefined)) {
                reviewSwiper = new Swiper('.js-reviews-slider', {
                    speed: 400,
                    slidesPerView: 1,
                    slidesPerGroup: 1,
                    spaceBetween: 20,
                    loop: true,
                    autoHeight: true,
                    pagination: {
                        el: $('.js-reviews-slider').find('.swiper-pager')[0],
                        type: 'bullets',
                        clickable: true
                    },
                });
            }else if (width >= 767 && (reviewSwiper != undefined)) {
                reviewSwiper.destroy();
                reviewSwiper = undefined;
                $('.js-reviews-slider').find('.swiper-wrapper').removeAttr('style');
                $('.js-reviews-slider').find('.swiper-slide').removeAttr('style');
            }
        }
        initReviewSwiper();
        $(window).on('resize orientationchange', () => {
            width = getWidth();
            initReviewSwiper();
        });
    }

    //bonuscard-stage-swiper
    if($('.page').find('.js-bonuscard-stage-swiper').length > 0){
        var bonusStageSwiper = undefined;
        function initBonusStageSwiper() {
            if ( width <= 599 && (bonusStageSwiper == undefined)) {
                bonusStageSwiper = new Swiper('.js-bonuscard-stage-swiper', {
                    slidesPerView: 'auto',
                    slidesOffsetBefore: 12,
                    slidesOffsetAfter: 12,
                    spaceBetween: 10,
                    loop: false,
                });
                bonusStageSwiper.on('progress', function(progress) {
                    if(progress < 1) {
                        $('.js-bonuscard-stage-swiper').removeClass('is-ended');
                    } else {
                        $('.js-bonuscard-stage-swiper').addClass('is-ended');
                    }
                });
            }else if (width > 599 && (bonusStageSwiper != undefined)) {
                bonusStageSwiper.destroy();
                bonusStageSwiper = undefined;
                $('.js-bonuscard-stage-swiper').find('.swiper-wrapper').removeAttr('style');
                $('.js-bonuscard-stage-swiper').find('.swiper-slide').removeAttr('style');
                $('.js-bonuscard-stage-swiper').find('.swiper-slide-duplicate').remove();
            }
        }
        initBonusStageSwiper();
        $(window).on('resize orientationchange', function() {
            width = Math.max($(window).width(), window.innerWidth);
            initBonusStageSwiper();
        });
    }

    //.js-bonuscard-bnfts-swiper
    if($('.page').find('.js-bonuscard-bnfts-swiper').length > 0){
        var bonusBnftSwiper = undefined;
        function initBonusBnftSwiper() {
            if ( width <= 750 && (bonusBnftSwiper == undefined)) {
                bonusBnftSwiper = new Swiper('.js-bonuscard-bnfts-swiper', {
                    slidesPerView: 'auto',
                    slidesOffsetBefore: 12,
                    slidesOffsetAfter: 12,
                    spaceBetween: 10,
                    loop: false,
                });
            }else if (width > 750 && (bonusBnftSwiper != undefined)) {
                bonusBnftSwiper.destroy();
                bonusBnftSwiper = undefined;
                $('.js-bonuscard-bnfts-swiper').find('.swiper-wrapper').removeAttr('style');
                $('.js-bonuscard-bnfts-swiper').find('.swiper-slide').removeAttr('style');
                $('.js-bonuscard-bnfts-swiper').find('.swiper-slide-duplicate').remove();
            }
        }
        initBonusBnftSwiper();
        $(window).on('resize orientationchange', function() {
            width = Math.max($(window).width(), window.innerWidth);
            initBonusBnftSwiper();
        });
    }

    //brands swiper
    var initBrandSwiper = function($el) {
        var slideNum = parseInt($el.data('slides'), 10);
        var productSwiper = new Swiper($el[0], {
            slidesPerView: 3,
            slidesPerGroup: 1,
            speed: 800,
            loop: true,
            loopAdditionalSlides: 2,
            navigation: {
                nextEl: $el.parent().find('.js-brands-slider-next')[0],
                prevEl: $el.parent().find('.js-brands-slider-prev')[0],
            },
            breakpoints: {
                600: {
                    slidesPerView: 4,
                    slidesPerGroup: 1,
                },
                750: {
                    slidesPerView: 5,
                    slidesPerGroup: 5,
                },
                1000: {
                    slidesPerView: 7,
                    slidesPerGroup: 7,
                },
                1150: {
                    slidesPerView: slideNum ? slideNum : 8,
                    slidesPerGroup: slideNum ? slideNum : 8,
                }
            }
        });
    };
    var brandSwiperList = $('.js-brands-swiper');
    brandSwiperList.each(function(index, el){
        var $this = $(el);
        initBrandSwiper($this);
    });

    // products mob swiper
    if($('.js-products-slider').length){
        let productMobSwiper = undefined;

        function initProductMobSwiper() {
            if( width <= 750 && (productMobSwiper == undefined)) {
                productMobSwiper = new Swiper('.js-products-slider', {
                    slidesPerView: 'auto',
                    slidesPerGroup: 1,
                    slidesOffsetBefore: 12,
                    slidesOffsetAfter: 12,
                    speed: 600,
                    watchOverflow: true
                });
            } else if (width > 750 && (productMobSwiper != undefined)) {
                productMobSwiper.destroy();
                productMobSwiper = undefined;
                $('.js-products-slider').find('.swiper-wrapper').removeAttr('style');
                $('.js-products-slider').find('.swiper-slide').removeAttr('style');
            }
        }
        initProductMobSwiper();
        $(window).on('resize orientationchange', function() {
            width = Math.max($(window).width(), window.innerWidth);
            initProductMobSwiper();
        });
    }

    //header infoblock close
    $('body').on('click', '.js-header-infoblock-close', function(e) {
        $(this).closest('.header-infoblock').hide();
        $('body').removeClass('has-top-message');
    });

    //datepicker
    moment.locale('ru');

    //mobfilter-toggle
    $('body').on('click', '.js-mobfilter-toggle', function(e) {
        $(this).toggleClass('is-open').next().slideToggle();
    });

    //catalog-menu current item scroll to visible area
    if(width <= 550 && $('body').find('.catalog-menu').length > 0){
        var scrollLeft = $('.catalog-menu__link.is-current').position().left;
        var widthItem = $('.catalog-menu__link.is-current').outerWidth();
        $('.catalog-menu').scrollLeft(scrollLeft - width/2 + widthItem/2);
    }

    if(width <= 650 && $('body').find('.usermenu').length > 0){
        var scrollLeft = $('.usermenu__list li.is-current').position().left;
        var widthItem = $('.usermenu__list li.is-current').outerWidth();
        $('.usermenu__row').scrollLeft(scrollLeft - width/2 + widthItem/2);
    }

    //all brands
    $('body').on('click', '.js-show-all-brands', function(e) {
        $(this).remove();
        $('.brands__link').show();
    });


    // simple slider
    const simpleSlider = new Swiper('.js-simple-slider', {
        slidesPerView: 1,
        slidesPerGroup: 1,
        loop: true,
        pagination: {
            el: $(this).find('.swiper-pager')[0],
            clickable: true
        }
    });

    // topslider
    const topslider = new Swiper('.js-topslider', {
        slidesPerView: 1,
        slidesPerGroup: 1,
        speed: 800,
        loop: true,
        effect: 'fade',
        fadeEffect: {
            crossFade: true
        },
        autoplay: {
            delay: 3500,
        },
        navigation: {
            nextEl: '.js-topslider-next',
            prevEl: '.js-topslider-prev',
        }
    });

    $("body").on('click', '.js-product-subs-tab', function(e){
        e.preventDefault();
        var $this = $(this);
        var tab = $this.attr('href');

        $('.js-product-subs-tab').not(this).removeClass('is-active');
        $this.addClass('is-active');
        $(".product-subs__offer-col").removeClass('is-active');
        $(tab).addClass('is-active');
    });

    $('.chat__content').jScrollPane({mouseWheelSpeed: 20, autoReinitialise: true});

    //card number
    $(".js-input-card").on('keyup', function () {
        if (this.value.length == this.maxLength) {
            $(this).next('.js-input-card').focus();
        }
    });

    $('body').on('change', '.js-delivery-radio', function(e) {
        var tab = $(this).data('tab');

        $('.order-contact__delivery-type-tab').removeClass('is-active');
        $('#'+tab).addClass('is-active');

        /*if($(this).data('tab') == 'showroom'){
          $('.order-contact__delivery-type-tab').slideDown();
        }else{
          $('.order-contact__delivery-type-tab').slideUp();
        }*/
    });

    $('body').on('click', '.js-delivery-comment-toggle', function(e) {
        e.preventDefault();
        $(this).toggleClass('is-open').next().slideToggle();
    });

    $('body').on('click', '.js-delivery-datetime-toggle', function(e) {
        e.preventDefault();
        $(this).toggleClass('is-open').next().slideToggle();
    });

    //chatlist select
    var listCheckboxes = $('.js-list-i-select');
    listCheckboxes.on('change', function () {
        if(!listCheckboxes.is(":checked")) {
            $('.js-list-i-select-all').prop('checked', false);
        }
    });
    $('.js-list-i-select-all').on('change', function () {
        listCheckboxes.prop('checked', $(this).prop('checked'));
    });


    $('.js-passport-adress').on('change', function () {
        if($(this).is(":checked")) {
            $('.order-passport__real-adress').hide();
        }else{
            $('.order-passport__real-adress').show();
        }
    });

    //item qty
    $(".js-qty-minus").on('click', function(){
        var inputbox = $(this).parent().children(".qty-box__input");
        var value = inputbox.val();
        if(value > 1){
            var res = parseInt(value) - 1;
        }else{
            var res = 1;
        }
        inputbox.val(res);
    });
    $(".js-qty-plus").on('click', function(){
        var inputbox = $(this).parent().children(".qty-box__input");
        var value = inputbox.val();
        var res = parseInt(value) + 1;
        inputbox.val(res);
    });

    //.js-subs-order-tab
    $('body').on('click', '.js-subs-order-tab', function(e) {
        e.preventDefault();
        var tab = $(this).attr('href');
        $('.subs-order__nav-link').not(this).removeClass('is-active');
        $('.subs-order__tab').removeClass('is-active');
        $(this).addClass('is-active');
        $(tab).addClass('is-active');
    });

    $('body').on('click', '.js-transactions-open', function(e) {
        $(this).hide().next().addClass('is-open');
        $(this).parent('.transactions-table__section').addClass('is-open');
    });
    $('body').on('click', '.js-transactions-close', function(e) {
        $(this).closest('.is-open').removeClass('is-open').prev().show();
        $(this).closest('.transactions-table__order-header').parent('.transactions-table__section').removeClass('is-open');
    });
    $('body').on('click', '.js-transactions-table-order-toggle', function(e) {
        $(this).closest('.transactions-table__section').toggleClass('is-open').find('.transactions-table__order-list').slideToggle();
    });

    $('body').on('click', '.js-order-open', function(e) {
        $(this).parent().hide().next().css('display', 'flex');
    });
    $('body').on('click', '.js-order-close', function(e) {
        $(this).closest('.order-table__i').hide().prev().show();
        $('.js-tarif-cont-toggle').removeClass('is-open');
        $('.tarif-dd').hide();
        if($(this).closest('.order-table__complect').hasClass('is-open')){
            $(this).closest('.order-table__complect').find('.js-order-complect-toggle').trigger('click');
        }
    });

    //order tarif toggle
    $('body').on('click', '.js-tarif-toggle', function(e) {
        e.preventDefault();
        e.stopPropagation();
        $('.js-tarif-toggle').not(this).next().fadeOut(150);
        $(this).toggleClass('is-open').next().fadeToggle(200);
    });
    $('body').on('click', '.tarif-dd', function(e) {
        e.stopPropagation();
    });

    $(document).click(function() {
        $('.js-tarif-toggle').removeClass('is-open').next().fadeOut(200);
    });

    $('body').on('focus', '.tarif-dd__val-input', function(e) {
        $(this).closest('.tarif-dd__option').find('.tarif-dd__radio').prop('checked', true);
    });

    //stick btn
    $(".order-passport__btn").stick_in_parent({offset_top: 15});
    $(".order-contact__submit-in").stick_in_parent({offset_top: 110});
    $('.cart__checkout-sticky').stick_in_parent({offset_top: 110});

    //selectbox
    $('body').on('click', '.js-selectbox-toggle', function(e) {
        e.stopPropagation();
        $('.select-styled.active').trigger('click');
        $('.filter-select.is-open').find('.js-filter-select-toggle').trigger('click');
        $('.js-selectbox-toggle').not(this).closest('.selectbox').removeClass('is-open');
        $(this).closest('.selectbox').toggleClass('is-open');
        if($(this).closest('.selectbox').hasClass('is-open'))
            $(this).next('.selectbox__dd').find('.selectbox__dd-scroll').jScrollPane();
    });
    $('body').on('click', '.js-selectbox-select', function(e) {
        e.stopPropagation();
        const text = $(this).html();
        const id = $(this).find('input').val();
        $(this).closest('.selectbox').find('.selectbox__btn-text').html(text);
        $(this).closest('.selectbox').find('input').first().val(id);
        $(this).closest('.selectbox').find('input').first().trigger('change');
        $(this).closest('.selectbox').removeClass('is-open');
    });
    $(document).click(function() {
        $('.selectbox').removeClass('is-open');
    });

    //selectbox-card
    $('body').on('click', '.js-selectbox-card-toggle', function(e) {
        e.stopPropagation();
        $(this).closest('.selectbox-card').toggleClass('is-open');
    });
    $('body').on('click', '.js-selectbox-card-select', function(e) {
        e.stopPropagation();
        var text = $(this).html();
        $(this).addClass('is-selected').siblings().removeClass('is-selected');
        $(this).closest('.selectbox-card').find('.selectbox-card__btn-text').html(text);
        $(this).closest('.selectbox-card').removeClass('is-open');
    });
    $(document).click(function() {
        $('.selectbox-card').removeClass('is-open');
    });

    $(".datetime-select__time-radio").on('change', function() {
        var val = $(this).val();
        $(this).closest('.order-contact__delivery-datetime').find('.js-delivery-datetime').text(val);
    });

    //order product list toggle
    $('body').on('click', '.js-order-productlist', function(e) {
        e.preventDefault();
        var listItem = $(this).closest('.order-list__row');
        listItem.toggleClass('is-open').find('.order-list__i-products').slideToggle();
        if(listItem.hasClass('is-open')){
            listItem.find('.show-text').hide();
            listItem.find('.hide-text').css('display', 'inline-block');
        }else{
            listItem.find('.show-text').css('display', 'inline-block');
            listItem.find('.hide-text').hide();
        }
    });

    //faq
    $('body').on('click', '.js-acc-toggle', function(e) {
        var $this = $(this);
        $('.js-acc-toggle').not(this).closest('.js-acc').removeClass('is-open').find('.faq__i-content ').slideUp(350);
        $this.closest('.js-acc').toggleClass('is-open').find('.faq__i-content ').slideToggle(350);
        if(width <= 750){
            setTimeout(function(){
                $('html, body').stop().animate({
                    scrollTop: ($this.offset().top - 75)
                },450);
            }, 350);
        }
    });

    $('body').on('click', '.js-subs-adress-list-toggle', function(e) {
        $(this).next().slideToggle();
    });

    $('.js-juro-contact').on('click', function(e) {
        e.preventDefault();
        $(this).parent().hide();
        $(this).closest('.order-contact').addClass('is-company-contact').find('.order-contact__company').show();
    });

    const focusFn = function(e) {
        $(this).blur().next().show(0);
        $(this).addClass('dadata');
        window.set_suggest(this);
    };
    $('.js-delivery-address-input').on('focus.selectmenu', focusFn);
    $('.js-delivery-address-input').on('click', function(e) {
        const elem = $(e.target);
        if (!elem.val() && elem.hasClass('dadata')) {
            elem.removeClass('dadata');
            window.unset_suggest(elem);
            focusFn.call(this);
        }
    });
    $('.js-delivery-address-off').on('click', function(e) {
        $(this).parent().hide(0);
        $(this).closest('.js-delivery-address').find('.js-delivery-address-input').off('focus.selectmenu').val('').focus();
        $(this).closest('.js-delivery-address').find('.address_id').val('');
    });
    $('.js-delivery-address-select').on('click', function(e) {
        const text = $(this).text().trim();
        const addr_id = $(this).find('.address_id').val();
        $(this).parent().hide(0);
        $(this).closest('.js-delivery-address').find('.js-delivery-address-input').val(text);
        $(this).closest('.js-delivery-address').find('.address_id').val(addr_id);
        const input = $(this).closest('.js-delivery-address').find('.js-delivery-address-input');
        input.removeClass('dadata');
        window.unset_suggest(input);
    });

    $('body').on('click', '.js-order-complect-toggle', function(e) {
        var parent = $(this).closest('.order-table__complect');
        parent.toggleClass('is-open').find('.order-table__complect-dd').slideToggle();
        if(width <=750 && parent.hasClass('is-open')){
            $('html, body').stop().animate({
                scrollTop: (parent.find('.order-table__complect-dd').offset().top - 55)
            }, 400);
        }
    });

    //filter-select
    $('body').on('click', '.js-filter-select-toggle', function(e) {
        e.stopPropagation();
        $('.select-styled.active').trigger('click');
        $('.selectbox.is-open').find('.js-selectbox-toggle').trigger('click');
        $('.js-filter-select-toggle').not(this).closest('.filter-select').removeClass('is-open');
        $(this).closest('.filter-select').toggleClass('is-open');
        if($(this).closest('.filter-select').hasClass('is-open'))
            $(this).next('.filter-select__value').find('.filter-select__list').jScrollPane({contentWidth: '0px'});
    });
    $('body').on('click', '.filter-select__value', function(e) {
        e.stopPropagation();
    });
    $(document).click(function() {
        $('.filter-select').removeClass('is-open');
    });
    $('.filter-select').each(function(indx, el){
        var num = $(el).find('.filter-select__list-input:checked').length;
        $(this).find('.filter-select__num').text('('+num+')');
        if(num > 0){
            $(this).find('.filter-select__title').addClass('has-checked');
        }else{
            $(this).find('.filter-select__title').removeClass('has-checked');
        }
    });
    $('body').on('change', '.filter-select .filter-select__list-input', function(e) {
        var num = $(this).closest('.filter-select__list').find('.filter-select__list-input:checked').length;
        $(this).closest('.filter-select').find('.filter-select__num').text('('+num+')');
        if(num > 0){
            $(this).closest('.filter-select').find('.filter-select__title').addClass('has-checked');
        }else{
            $(this).closest('.filter-select').find('.filter-select__title').removeClass('has-checked');
        }
    });
    $('body').on('click', '.js-filter-select-clear', function(e) {
        $(this).closest('.filter-select__title').find('.filter-select__num').text('(0)');
        $(this).closest('.filter-select').find('.filter-select__list-input').prop('checked',false);
        $(this).closest('.filter-select__title').removeClass('has-checked');
    });
    $('body').on('click', '.js-cat-filter-reset', function(e) {
        $('.filter-select__num').text('(0)');
        $('.filter-select__list-input').attr('checked', false);
        $('.cat-filter__boolean-input').attr('checked', false);
        $('.filter-select__title').removeClass('has-checked');
    });

    //about-rent toggle
    $('body').on('click', '.js-about-rent-toggle', function(e) {
        e.preventDefault();
        $(this).toggleClass('is-open').closest('.bnfts').find('.bnfts__about').slideToggle();
    });

    //review-toggle
    $('body').on('click', '.js-review-toggle', function(e) {
        e.preventDefault();
        $(this).closest('.review').toggleClass('is-open');
    });

    //rate
    $('#set-rate').barrating({
        theme: 'css-stars',
        showSelectedRating: false,
        initialRating: 0
    });



    //orders select
    var orderCheckboxes = $('.js-order-select');
    orderCheckboxes.on('change', function () {
        if(!orderCheckboxes.prop('checked') == true){
            $('.js-orders-select-all').prop('checked', false);
        }
        if($('.js-order-select:checked').length < orderCheckboxes.length) {
            $('.js-orders-select-all').prop('checked', false);
        }
        if($('.js-order-select:checked').length > 0){
            $('.user-orders__cancel').removeClass('is-disabled');
            $('.js-accounts-download').removeClass('is-disabled');
        }else{
            $('.user-orders__cancel').addClass('is-disabled');
            $('.js-accounts-download').addClass('is-disabled');
        }
    });
    $('.js-orders-select-all').on('change', function () {
        orderCheckboxes.prop('checked', $(this).prop('checked'));
        if($(this).prop('checked')){
            $('.user-orders__cancel').removeClass('is-disabled');
            $('.js-accounts-download').removeClass('is-disabled');
        }else{
            $('.user-orders__cancel').addClass('is-disabled');
            $('.js-accounts-download').addClass('is-disabled');
        }

    });

    //cat filter subcat list toggle-btn
    if(($('.cat-filter__subcat-list li').outerWidth()*$('.cat-filter__subcat-list li').length) < $('.cat-filter__subcat-list').outerWidth()){
        $('.cat-filter__subcat-box').addClass('is-short');
    }

    //pop-request toggle
    $('body').on('click', '.js-pop-request-toggle', function(e) {
        $(this).parent().toggleClass('is-open');
    });

    //rentrules-pay-guarantee toggle
    $('body').on('click', '.js-rentrules-pay-guarantee', function(e) {
        e.preventDefault();
        $(this).toggleClass('is-open');
        $($(this).attr('href')).slideToggle();
    });

    //.js-bonustable-list-toggle
    $('body').on('click', '.js-bonustable-list-toggle', function(e) {
        $(this).toggleClass('is-open').closest('.bonustable__i').next().slideToggle();
    });

    // pickpoint-select scroller
    window.pickpointScrollApi = false;
    window.initPickpointScroll = () => {
        $('.pickpoint-select__scroller')
            .bind(
                'jsp-scroll-y',
                function(event, scrollPositionY, isAtTop, isAtBottom)
                {
                    const scroller = $(this);
                    if(isAtBottom) {
                        scroller.addClass('_end-scroll');
                    } else {
                        scroller.removeClass('_end-scroll');
                    }

                    if(isAtTop) {
                        scroller.addClass('_start-scroll');
                    } else {
                        scroller.removeClass('_start-scroll');
                    }
                }
            )
            .jScrollPane({contentWidth: '0px'});
        window.pickpointScrollApi = $('.pickpoint-select__scroller').data().jsp;
    };

    if($('.pickpoint-select__scroller').length) {
        if (width > 1020) {
            window.initPickpointScroll();
        }

        $(window).on('resize orientationchange', function () {
            if (width > 1020) {
                if (!window.pickpointScrollApi) {
                    window.initPickpointScroll();
                }
            } else {
                if (window.pickpointScrollApi) {
                    window.pickpointScrollApi.destroy();
                    window.pickpointScrollApi = false;
                }
            }
        });
    }

    window.posScrollApi = false;
    window.initPosScroll = () => {
        $('.pos-select__scroller')
            .bind(
                'jsp-scroll-y',
                function(event, scrollPositionY, isAtTop, isAtBottom)
                {
                    const scroller = $(this);
                    if(isAtBottom) {
                        scroller.addClass('_end-scroll');
                    } else {
                        scroller.removeClass('_end-scroll');
                    }

                    if(isAtTop) {
                        scroller.addClass('_start-scroll');
                    } else {
                        scroller.removeClass('_start-scroll');
                    }
                }
            )
            .jScrollPane({contentWidth: '0px'});
        window.posScrollApi = $('.pos-select__scroller').data().jsp;
    };

    if($('.pos-select__scroller').length) {
        if (width > 1020) {
            window.initPosScroll();
        }

        $(window).on('resize orientationchange', function () {
            if (width > 1020) {
                if (!window.posScrollApi) {
                    window.initPosScroll();
                }
            } else {
                if (window.posScrollApi) {
                    window.posScrollApi.destroy();
                    window.posScrollApi = false;
                }
            }
        });
    }
    $('[data-pickpoint-popup]').on('click', function() {
        if (window.pickpointScrollApi) {
            window.pickpointScrollApi.destroy();
            window.pickpointScrollApi = false;
        }
        setTimeout(window.initPickpointScroll, 1000);
    });
    // END pickpoint-select scroller

    // pickpoint-select mob toggle
    $('.js-pickpoint-toggle').on('click', function() {
        const $this = $(this);
        let btnText = $this.data('open');
        $this.toggleClass('is-open').prev().toggleClass('is-open');
        if($this.hasClass('is-open')) {
            btnText = $this.data('close');
        }
        $this.children('span').text(btnText);
    });
    // END pickpoint-select mob toggle

    // checkout tab toggle
    $('body').on('change', '.js-checkout-tab-toggle', function(e) {
        $this = $(this);
        if($this.is(':checked')) {
            const tab = $this.data('tab');
            if ($this.closest('.js-checkout-tabs').hasClass('disabled')) {
                return;
            }
            $this.closest('.js-checkout-tabs').find('.js-checkout-tab').removeClass('is-active');
            $('#' + tab).addClass('is-active');

            if(tab == 'pickpoint' && pickpointScrollApi) {
                pickpointScrollApi.destroy();
                pickpointScrollApi = false;
                initPickpointScroll();
            }
            if (tab === 'pos' && posScrollApi) {
                posScrollApi.destroy();
                posScrollApi = false;
                initPosScroll();
            }
        }
    });
    // END checkout tab toggle

    //alert-message-toggle
    $('body').on('click', '.js-alert-toggle', function(e) {
        e.preventDefault();
        $(this).toggleClass('is-open').closest('.alert-message').find('.alert-message__hidden ').slideToggle();
    });

    $('body').on('click', '.js-minsum-message', function(e) {
        e.preventDefault();
        $(this).toggleClass('is-open').next().slideToggle();
    });

    //copytext
    $('body').on('click', '.js-copytext', function() {
        var text = $(this).prev().val();
        var container = $(this).closest('.copytext');
        var $tempInput = $('<input>');
        $('body').append($tempInput);
        $tempInput.val(text).select();
        document.execCommand('copy');
        container.addClass('is-success');
        $tempInput.remove();
        setTimeout(function() {
            container.removeClass('is-success');
        }, 2000);
    });

    $('body').on('click', '.js-entity-menu-toggle', function() {
        $(this).closest('.entity-menu').toggleClass('is-open');
    });

    $('body').on('keyup input', '.js-subscribe-validate', function() {
        var regex = /^([a-zA-Z0-9_.+-])+\@(([a-zA-Z0-9-])+\.)+([a-zA-Z0-9]{2,4})+$/;
        var btn = $(this).closest('.subscribe-form').find('button');
        if(regex.test($(this).val())) {
            btn.prop('disabled', false);
        } else {
            btn.prop('disabled', true);
        }
    });

    //catalog filter
    $('body').on('click', '.js-filter-toggle', function(e) {
        $(this).toggleClass('is-open');
        if(width <= 750)
            $('body').toggleClass('is-page-fixed');
        $('.cat-filter__filter-hidden').toggle(0);
    });

    $('body').on('click', '.js-checkboxlist-toggle', function() {
        var el = $(this);
        el.prev().toggleClass('is-open');
        el.text() == el.data("text-show")
            ? el.text(el.data("text-close"))
            : el.text(el.data("text-show"));
    });

    $('body').on('click', '.js-catfilter-close', function() {
        $('.js-filter-toggle').trigger('click');
    });

    //seo-key toggle
    $('body').on('click', '.js-seo-key-toggle', function(e) {
        var el = $(this);
        var label = el.children('span');
        el.parent().toggleClass('is-open');
        label.text() == el.data('text-show')
            ? label.text(el.data('text-close'))
            : label.text(el.data('text-show'));
    });

    //filter value's list toggle
    $('body').on('click', '.js-filterlist-toggle', function() {
        var el = $(this);
        el.prev().toggleClass('is-open');
        el.text() == el.data('text-show')
            ? el.text(el.data('text-close'))
            : el.text(el.data('text-show') );
    });

    //filter section toggle
    $('body').on('click', '.js-filter-section-toggle', function() {
        $(this).toggleClass('is-open').next().slideToggle(250);
    });

    //mob filter toggle
    $('body').on('click', '.js-mobfilter-open', function() {
        $('body').addClass('is-filter-open');
        setTimeout(function() {
            $('body').addClass('is-page-fixed');
        }, 450);
    });
    $('body').on('click', '.js-mobfilter-close', function() {
        $('body').removeClass('is-page-fixed').removeClass('is-filter-open');
    });

    //price sliders
    if($('.js-price-slider').length) {
        var slider = $('.js-price-slider');
        var parent = slider.closest('.filter-price');
        var min = parseInt(slider.data('min'), 10);
        var max = parseInt(slider.data('max'), 10);
        var from = parseInt(slider.data('from'), 10);
        var to = parseInt(slider.data('to'), 10);
        var inputMin = parent.find('.js-price-min')[0];
        var inputMax = parent.find('.js-price-max')[0];
        noUiSlider.create(slider[0], {
            start: [from, to],
            range: {
                'min': [min],
                'max': [max]
            },
            connect: true,
            step: 1,
            format: {
                to: function (value) {
                    return value.toLocaleString();
                },
                from: function (value) {
                    return value.toLocaleString();
                }
            },
        });
        slider[0].noUiSlider.on('update', function (values, handle) {
            (handle ? inputMax : inputMin).value = values[handle];
        });

        //filter input change
        parent.find('.js-price-min').on('change', function() {
            if($(this).val() < slider[0].noUiSlider.get()[1]) {
                slider[0].noUiSlider.set([parseInt($(this).val(), 10), null]);
            } else {
                $(this).val(slider[0].noUiSlider.get()[0]);
            }
        });
        parent.find('.js-price-max').on('change', function() {
            if($(this).val() > slider[0].noUiSlider.get()[0]) {
                slider[0].noUiSlider.set([null, parseInt($(this).val(), 10)]);
            } else {
                $(this).val(slider[0].noUiSlider.get()[1]);
            }
        });
    }

    $('body').on('click', '.js-mob-sort-toggle', function(e) {
        e.preventDefault();
        e.stopPropagation();
        $(this).next().find('.select-styled').trigger('click');
    });

    //product tariff toggle
    $('body').on('click', '.js-product-tariff-active', function(e) {
        e.preventDefault();
        $(this).closest('.product-tariff__option').addClass('is-active');
        $('.product-tariff').addClass('has-active');
    });
    $('body').on('click', '.js-product-tariff-toggle', function() {
        $(this).closest('.product-tariff__option').addClass('is-active').siblings().removeClass('is-active')
    });

    //order-products toggle
    $('body').on('click', '.js-order-products-toggle', function() {
        $(this).prev().toggleClass('is-open');
    });

    //order-preview toggle
    $('body').on('click', '.js-order-preview-toggle', function() {
        $(this).closest('.order-preview').toggleClass('is-open');
    });

    //orderpage-form toggle
    $('body').on('click', '.js-orderpage-form-toggle', function() {
        $(this).closest('.orderpage__delivery').toggleClass('is-edit');
    });

    //orderpage-payment toggle
    $('body').on('click', '.js-orderpage-payment-toggle', function() {
        $(this).toggleClass('is-open').next().slideToggle();
    });

    //.js-orderpage-payment-more
    $('body').on('click', '.js-orderpage-payment-more', function(e) {
        e.preventDefault();
        let showStep = $(this).data('showStep');
        $(this).closest('.orderpage__payment-list').find('.payment-table-row:hidden').slice(0,showStep).css('display', 'flex');

        if($('.payment-table-row:hidden').length < 1)
            $('.js-orderpage-payment-more').hide();
    });

    // .js-tariff-more
    $('.js-tariff-more-open').on('click', () => {
        $('.product-tariff__option').removeClass('is-active');
        $('.product-tariff').removeClass('has-active');
        $('.product-simple__tariffs').addClass('_hidden-tariff-view');
    });

    $('.js-tariff-more-close').on('click', () => {
        $('.product-simple__tariffs').removeClass('_hidden-tariff-view');
        $('.product-tariff__option').removeClass('is-active');
        $('.product-tariff').removeClass('has-active');
    });

});

$(window).on("resize orientationchange", function(e) {
    //only horizontal resize
    if( width != $( window ).width() ){
        width = $( window ).width();
        height = window.innerHeight;

        checkWindowSize();

        equalheight('.product-subs__offer-info');
        equalheight('.product-subs__offer-term');

        $('.js-tarif-toggle').removeClass('is-open').next().fadeOut(200);
        $('.js-tarif-cont-toggle').removeClass('is-open');
        $('.tarif-dd').fadeOut(200);
    }
});
